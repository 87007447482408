// import React, { memo, useEffect, useState } from 'react';
// import ReportsCard from '../../Components/ReportsCard';
// import ReportsTable from '../../Components/ReportsCard/tablereports';
// import DatePicker from 'react-datepicker'; // Import a date picker component
// import 'react-datepicker/dist/react-datepicker.css';
// import './style.css';

// const reportsData = [
//   {
//     id: 1,
//     logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" fill="blue"/></svg>',
//     title: 'Dialing Report',
//     apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/dialingReport.php'
//   },
//   {
//     id: 2,
//     logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z" fill="blue"/></svg>',
//     title: 'Appointment Report',
//     apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/appointmentReports.php'
//   },
//   {
//     id: 3,
//     logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 256h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm256-32H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16z" fill="blue"/></svg>',
//     title: 'Dial Status Report Current Day By Agent',
//     apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/dial_status_report.php'
//   },
//   {
//     id: 4,
//     logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" fill="blue"/></svg>',
//     title: 'Dial Status Report By Date',
//     apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/dial_status_report_by_date.php'
//   },
//   {
//     id: 5,
//     logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM112 192H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" fill="blue"/></svg>',
//     title: 'Dial Status Report',
//     apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/dial_status_report_count.php'
//   },
//   {
//     id: 6,
//     logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" fill="blue"/></svg>',
//     title: 'Call Logs Count Report',
//     apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/call_logs_count.php'
//   },
//   {
//     id: 7,
//     logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM288 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm211.3-43.3c-6.2-6.2-16.4-6.2-22.6 0L416 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z" fill="blue"/></svg>',
//     title: 'Call Logs Details',
//     apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/callLog_customerDetails.php'
//   },
//   {
//     id: 8,
//     logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" fill="blue"/></svg>',
//     title: 'Tele Customer Report by Current Status',
//     apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/readSelected.php',
//     filterParams: {
//       tbname: " tele_customer",
//       propname: " tele_customer",
//       cols: "rm_id,provider_id,provider_name,product_id,product_name,premium,sum_assured,customer_status,premium,premium_mode,comments,previous_comments,agent_incentive, current_status",
//       filters: "current_status IN ('accepted','intrested','Underwriter Review')",
//     }
//   },
//   {
//     id: 9,
//     logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM112 192H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" fill="blue"/></svg>',
//     title: 'Tele Customer Report by Customer Status',
//     apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/rm_customer_status.php',
//     filterParams: {
//       tbname: "tele_customer",
//       propname: "tele_customer",
//       cols: "rm_id,provider_id,provider_name,product_id,product_name,premium,sum_assured,customer_status,premium,premium_mode,comments,previous_comments,agent_incentive",
//       filters: "customer_status IN ('inactive','active')"
//     }
//   },
//   {
//     id: 10,
//     logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM112 192H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" fill="blue"/></svg>',
//     title: 'Tele Customer Report by RM Name',
//     apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/readSelected.php',
//     filterParams: {
//       tbname: " tele_customer",
//       propname: " tele_customer",
//       cols: "customer_name,rm_id,commission_status,commission,provider_id,provider_name,product_id,product_name,premium,sum_assured,premium,premium_mode,comments,previous_comments,agent_incentive",
//       filters: "rm_id IN ('25','24')"
//     }
//   }

// ];

// const ReportsComponents = () => {
//   const [apiData, setApiData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [selectedReportId, setSelectedReportId] = useState(-1);
//   const [agentName, setAgentName] = useState('');
//   const [filteredData, setFilteredData] = useState({});
//   const [selectedDate, setSelectedDate] = useState(null); // State for selected date
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [showFilters, setShowFilters] = useState(false);
//   const [editMode, setEditMode] = useState(false); // State to track edit mode
//   const [filterVisibility, setFilterVisibility] = useState({
//     8: false, // Initial state for report with ID 8
//     9: false, // Initial state for report with ID 9
//   });
//   const [editModeVisibility, setEditModeVisibility] = useState({});


//   const handleShowTable = (reportId) => {
//     setSelectedReportId((prevId) => (prevId === reportId ? -1 : reportId));
//   };

//   // const handleFilterSelection = (filter, apiEndpoint) => {
//   //   console.log('Selected filter:', filter);
//   //   setSelectedFilters((prevFilters) => {
//   //     // Check if the filter is already selected
//   //     const isFilterSelected = prevFilters.some(f => f.filter === filter && f.apiEndpoint === apiEndpoint);

//   //     if (isFilterSelected) {
//   //       // Filter is already selected, remove it
//   //       return prevFilters.filter((f) => !(f.filter === filter && f.apiEndpoint === apiEndpoint));
//   //     } else {
//   //       // Filter is not selected, add it
//   //       return [...prevFilters, { filter, apiEndpoint }];
//   //     }
//   //   });
//   // };

//   const handleFilterSelection = (filter, apiEndpoint) => {
//     setSelectedFilters((prevFilters) => {
//       // Check if the filter is already selected
//       const isFilterSelected = prevFilters.some(
//         (f) => f.filter === filter && f.apiEndpoint === apiEndpoint
//       );
  
//       if (isFilterSelected) {
//         // Filter is already selected, remove it
//         return prevFilters.filter(
//           (f) => !(f.filter === filter && f.apiEndpoint === apiEndpoint)
//         );
//       } else {
//         // Filter is not selected, add it
//         return [...prevFilters, { filter, apiEndpoint }];
//       }
//     });
//   };
  



//   const handleToggleEditMode = () => {
//     setEditMode(!editMode);
//   };

//   // Function to toggle filter visibility for a report
//   const toggleFilterVisibility = (reportId) => {
//     console.log('Toggling filter visibility for report ID:', reportId);
//     setFilterVisibility((prevVisibility) => ({
//       ...prevVisibility,
//       [reportId]: !prevVisibility[reportId],
//     }));
//   };


//   console.log('Filter visibility state:', filterVisibility);
//   console.log('Selected filters:', selectedFilters);
//   // Ensure that the filteredData state is updated correctly
// console.log("Filtered Data:", filteredData); // Log the filtered data state






//   // Function to toggle edit mode visibility for a report
//   const toggleEditModeVisibility = (reportId) => {
//     setEditModeVisibility((prevVisibility) => ({
//       ...prevVisibility,
//       [reportId]: !prevVisibility[reportId],
//     }));
//   };



//   useEffect(() => {
//     const fetchDataForAllEndpoints = async () => {
//       setLoading(true);
//       setApiData({});

//       try {
//         for (const report of reportsData) {
//           const { apiEndpoint, filterParams } = report;
//           const requestBody = generateRequestBody(filterParams, report.filters);

//           try {
//             const response = await fetch(apiEndpoint, {
//               method: 'POST',
//               headers: {
//                 'Content-Type': 'application/json',
//               },
//               body: requestBody,
//             });

//             const data = await response.json();
//             console.log("Data from API:", data); // Log the fetched data
//             if (data.status === 'success' && data.data) {
//               setApiData(prevData => ({
//                 ...prevData,
//                 [apiEndpoint]: data.data,
//               }));
//             } else {
//               console.error(`Error in API response for ${apiEndpoint}:`, data.status, data.message);
//             }
//           } catch (error) {
//             console.error(`Error fetching data for ${apiEndpoint}:`, error);
//           }
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }

//       setLoading(false);
//     };

//     // const generateRequestBody = (filterParams, filters) => {
//     //   const requestBody = {
//     //     tbname: filterParams ? filterParams.tbname : "tele_customer",
//     //     propname: filterParams ? filterParams.propname : "tele_customer",
//     //     cols: filterParams ? filterParams.cols : "rm_id,provider_id,provider_name,product_id,product_name,premium,sum_assured,customer_status,premium,premium_mode,comments,previous_comments,agent_incentive,current_status,customer_name,id,policy_date,proposal_date,postpone,agent_calls_id,agent_id,postpone_reason,previous_comments,postpone_date,policy_number,policy_name,agent_incentive,appointment_type,appointment_reason",
//     //   };

//     //   if (filterParams && filterParams.filters) {
//     //     requestBody.filters = filterParams.filters;
//     //   } else if (filters && filters.length > 0) {
//     //     requestBody.filters = `current_status IN (${filters.map(filter => `'${filter}'`).join(',')})`;
//     //   }

//     //   return JSON.stringify(requestBody);
//     // };

//     const generateRequestBody = (filterParams, filters) => {
//       const requestBody = {
//         tbname: filterParams ? filterParams.tbname : "tele_customer",
//         propname: filterParams ? filterParams.propname : "tele_customer",
//         cols: filterParams ? filterParams.cols : "rm_id,provider_id,provider_name,product_id,product_name,premium,sum_assured,customer_status,premium,premium_mode,comments,previous_comments,agent_incentive,current_status,customer_name,id,policy_date,proposal_date,postpone,agent_calls_id,agent_id,postpone_reason,previous_comments,postpone_date,policy_number,policy_name,agent_incentive,appointment_type,appointment_reason",
        
//       };
//       console.log("Generated Request Body:",requestBody);
    
//       if (filters && filters.length > 0) {
//         requestBody.filters = `current_status IN (${filters.map(filter => `'${filter.filter}'`).join(',')})`;
//       }
    
//       return JSON.stringify(requestBody);
//     };
    

//     fetchDataForAllEndpoints();
//   }, []);

  

//   useEffect(() => {
//     // const filterDataByNameAndDate = () => {
//     //   const filteredData = {};

//     //   for (const report of reportsData) {
//     //     const { apiEndpoint } = report;
//     //     const data = apiData[apiEndpoint];

//     //     if (data) {
//     //       let filtered = [...data]; // Create a copy of the data array to avoid mutating the original

//     //       // Filter based on agent name
//     //       if (agentName.trim() !== '') {
//     //         filtered = filtered.filter(
//     //           (item) =>
//     //             item.first_name &&
//     //             item.first_name.toLowerCase().includes(agentName.toLowerCase())
//     //         );
//     //       }

//     //       // Filter based on selected date
//     //       if (selectedDate) {
//     //         filtered = filtered.filter(
//     //           (item) =>
//     //             item.call_date && new Date(item.call_date).toDateString() === selectedDate.toDateString()
//     //         );
//     //       }

//     //       // Filter based on selected filters associated with the current API endpoint
//     //       const filtersForEndpoint = selectedFilters.filter(f => f.apiEndpoint === apiEndpoint);
//     //       if (filtersForEndpoint.length > 0) {
//     //         filtered = filtered.filter(
//     //           (item) =>
//     //             item.current_status && filtersForEndpoint.some(f => f.filter === item.current_status)
//     //         );
//     //       }

//     //       filteredData[apiEndpoint] = filtered;
//     //     } else {
//     //       console.error(`No data found for API endpoint: ${apiEndpoint}`);
//     //     }
//     //   }

//     //   setFilteredData(filteredData);
//     // };


//     const filterDataByNameAndDate = () => {
//       const filteredData = {};
    
//       for (const report of reportsData) {
//         const { apiEndpoint } = report;
//         const data = apiData[apiEndpoint];
    
//         if (data) {
//           let filtered = [...data]; // Create a copy of the data array to avoid mutating the original
    
//           // Filter based on agent name
//           if (agentName.trim() !== '') {
//             filtered = filtered.filter(
//               (item) =>
//                 item.first_name &&
//                 item.first_name.toLowerCase().includes(agentName.toLowerCase())
//             );
//           }
    
//           // Filter based on selected date
//           if (selectedDate) {
//             filtered = filtered.filter(
//               (item) =>
//                 item.call_date &&
//                 new Date(item.call_date).toDateString() ===
//                   selectedDate.toDateString()
//             );
//           }
    
//           // Filter based on selected filters associated with the current API endpoint
//           // const filtersForEndpoint = selectedFilters.filter(
//           //   (f) => f.apiEndpoint === apiEndpoint
//           // );
//           // if (filtersForEndpoint.length > 0) {
//           //   filtered = filtered.filter((item) =>
//           //     filtersForEndpoint.some((f) => f.filter === item.current_status)
//           //   );
//           // }

//           const filtersForEndpoint = selectedFilters.filter(
//             (f) => f.apiEndpoint === apiEndpoint
//           );
//           if (filtersForEndpoint.length > 0) {
//             filtered = filtered.filter((item) =>
//               filtersForEndpoint.some((f) => f.filter === item.current_status)
//             );
//           }
          
    
//           filteredData[apiEndpoint] = filtered;
//         } else {
//           console.error(`No data found for API endpoint: ${apiEndpoint}`);
//         }
//       }
    
//       setFilteredData(filteredData);
//     };
    


//     filterDataByNameAndDate();
//   }, [agentName, selectedDate, apiData, selectedFilters]);





//   return (
//     <>
//       <div className='searchContainer'>
//         <input
//           className='searchName'
//           type="text"
//           placeholder="Enter Agent's Name"
//           value={agentName}
//           onChange={(e) => setAgentName(e.target.value)}
//         />
//         <DatePicker // Date picker component
//           className='datePicker'
//           selected={selectedDate}
//           onChange={call_date => setSelectedDate(call_date)}
//           dateFormat="yyyy-MM-dd"
//           placeholderText="Select Date"
//         />
//         <svg xmlns="http://www.w3.org/2000/svg" className='searchIcon' height="15" viewBox="0 0 512 512">
//           <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
//         </svg>
//         <svg xmlns="http://www.w3.org/2000/svg" className='dateIcon' height="15" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z" /></svg>
//       </div>
//       <div>
//         {/* Filter for report with ID 8 */}
//         {reportsData.find(report => report.id === 8) && (
//           <div className='filtersContainer'>
//             <div className='filterIcon' onClick={() => toggleFilterVisibility(8)}>
//               Filter <i className='fa fa-filter'></i>
//               <div className={`filterMenu ${filterVisibility[8] ? 'active' : ''}`}>
//                 <label>
//                   <input
//                     type="checkbox"
//                     value="intrested"
//                     checked={selectedFilters.some(f => f.filter === "intrested" && f.apiEndpoint === 'https://api.indiainsurancedeals.com/v1/common/readSelected.php')}
//                     onChange={() => handleFilterSelection("intrested", 'https://api.indiainsurancedeals.com/v1/common/readSelected.php')}
//                   />
//                   Interested
//                 </label>
//                 <label>
//                   <input
//                     type="checkbox"
//                     value="Underwriter Review"
//                     checked={selectedFilters.some(f => f.filter === "Underwriter Review" && f.apiEndpoint === 'https://api.indiainsurancedeals.com/v1/common/readSelected.php')}
//                     onChange={() => handleFilterSelection("Underwriter Review", 'https://api.indiainsurancedeals.com/v1/common/readSelected.php')}
//                   />
//                   Underwriter Review
//                 </label>
//               </div>
//             </div>
//           </div>
//         )}

//         {/* Filter for report with ID 9 */}
//         {reportsData.find(report => report.id === 9) && (
//           <div className='filtersCustomer'>
//             <div className='filterCIcon' onClick={() => toggleFilterVisibility(9)}>
//               Filter <i className='fa fa-filter'></i>
//               <div className={`filterMenuCust ${filterVisibility[9] ? 'active' : ''}`}>
//                 <label>
//                   <input
//                     type="checkbox"
//                     value="active"
//                     checked={selectedFilters.some(f => f.filter === "active" && f.apiEndpoint === 'https://api.indiainsurancedeals.com/v1/common/readSelected.php')}
//                     onChange={() => handleFilterSelection("active", 'https://api.indiainsurancedeals.com/v1/common/readSelected.php')}
//                   />
//                   Active
//                 </label>
//                 <label>
//                   <input
//                     type="checkbox"
//                     value="inactive"
//                     checked={selectedFilters.includes("inactive")}
//                     onChange={() => handleFilterSelection("inactive")}
//                   />
//                   Inactive
//                 </label>
//               </div>
//             </div>
//           </div>
//         )}

//         {reportsData.find(report => report.id === 10) && (
//           <div className='filtersRM'>
//             <div className='filterRIcon' onClick={() => toggleFilterVisibility(10)}>
//               Filter <i className='fa fa-filter'></i>
//               <div className={`filterMenuRM ${filterVisibility[10] ? 'active' : ''}`}>
//                 <label>
//                   <input
//                     type="checkbox"
//                     value="active"
//                     checked={selectedFilters.some(f => f.filter === "25" && f.apiEndpoint === 'https://api.indiainsurancedeals.com/v1/common/readSelected.php')}
//                     onChange={() => handleFilterSelection("25", 'https://api.indiainsurancedeals.com/v1/common/readSelected.php')}
//                   />
//                   25
//                 </label>
//                 <label>
//                   <input
//                     type="checkbox"
//                     value="active"
//                     checked={selectedFilters.some(f => f.filter === "24" && f.apiEndpoint === 'https://api.indiainsurancedeals.com/v1/common/readSelected.php')}
//                     onChange={() => handleFilterSelection("24", 'https://api.indiainsurancedeals.com/v1/common/readSelected.php')}
//                   />
//                   24
//                 </label>
//               </div>

//             </div>
//           </div>
//         )}
//       </div>



//       {reportsData.find(report => report.id === 9) && (
//         <button onClick={handleToggleEditMode} className="editButton">
//           {editMode ? 'Save' : 'Edit'}
//         </button>
//       )}

//       {loading ? (
//         <div className="loading"></div>
//       ) : (
//         <div className="reportsContainer">
//           {reportsData.map((report) => {
//             const { id, apiEndpoint } = report;
//             return (
//               <React.Fragment key={id}>
//                 <ReportsCard {...report} onShowTable={() => handleShowTable(id)} />
//                 {selectedReportId === id && (
//                   <React.Fragment>
//                     {/* Code to toggle edit mode visibility */}
//                     {id === 9 && (
//                       <button onClick={() => toggleEditModeVisibility(id)} className="editButton">
//                         {editModeVisibility[id] ? 'Save' : 'Edit'}
//                       </button>
//                     )}
//                     {/* ReportsTable component */}
//                     <ReportsTable 
//                       key={apiEndpoint}
//                       apiEndpoints={[apiEndpoint]}
//                       agentName={agentName}
//                       data={filteredData}
//                       show={selectedReportId === id}
//                       editMode={editModeVisibility[id]} // Pass edit mode visibility as a prop
//                     />
//                   </React.Fragment>
//                 )}
//               </React.Fragment>
//             );
//           })}
//         </div>

//       )}

//     </>
//   );
// };

// export default memo(ReportsComponents);

import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'

const ReportCard = ({ title, brief, link }) => {
  return (
    <div className="report-card">
      <h2>{title}</h2>
      <p>{brief}</p>
      <Link to={link}>View Reports</Link>
    </div>
  );
};


const Reports = () => {
  return ( 
    <div className="reports-container">
      <ReportCard
        title="Agent Report"
        brief="This report provides information about agents."
        link="/agentreports"
      />
      <ReportCard
        title="Calls Report"
        brief="This report provides information about calls."
        link="/calllogreports"
      />
      <ReportCard
        title="Telecustomer Report"
        brief="This report provides information about telecustomers."
        link="/telecustomerreports"
      />
      {/* Add more report cards here */}
    </div>
  );
}
 
export default Reports;





