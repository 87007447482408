const Customer = () => {
    return ( 
        <>
            <div className="main-container">
                <div className="sub-container">
                    <h4>Customer Page</h4>
                </div>
            </div>
        </>
     );
}
 
export default Customer;