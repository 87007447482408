import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Updateproduct.css';
import Navbar from '../../Components/Navbar';

function UpdateProduct() {
  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState(['']);
  const [productBenefits, setProductBenefits] = useState(['']);
  const [productHighlights, setProductHighlights] = useState(['']);
  const [productList, setProductList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [state, setState] = useState(true);

  useEffect(() => {
    // Fetch the list of products when the component mounts
    fetchProductList();
  }, []);

  const fetchProductList = () => {
    // Replace with your API endpoint to get the list of products
    axios
      .get(`http://localhost:5000/products`)
      .then((response) => {
        setProductList(response.data);
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
  };

  const handleUpdateClick = (id, name) => {
    // Find the selected product in the product list
    const selectedProduct = productList.find((product) => product.id === id);
    if (selectedProduct) {
      setProductId(selectedProduct.id);
      setProductName(selectedProduct.productname); // Set product name here
      setProductDescription([selectedProduct.productdescription || '']);
      setProductBenefits(selectedProduct.Benefits || ['']);
      setProductHighlights(selectedProduct.Highlights || ['']);
    }
    setState(false);
  };

  const handleAddItem = (section) => {
    if (section === 'benefits') {
      setProductBenefits([...productBenefits, '']);
    } else if (section === 'highlights') {
      setProductHighlights([...productHighlights, '']);
    } else if (section === 'description') {
      setProductDescription([...productDescription, '']);
    }
  };

  const handleRemoveItem = (section, index) => {
    if (section === 'benefits') {
      const updatedBenefits = [...productBenefits];
      updatedBenefits.splice(index, 1);
      setProductBenefits(updatedBenefits);
    } else if (section === 'highlights') {
      const updatedHighlights = [...productHighlights];
      updatedHighlights.splice(index, 1);
      setProductHighlights(updatedHighlights);
    } else if (section === 'description') {
      const updatedDescription = [...productDescription];
      updatedDescription.splice(index, 1);
      setProductDescription(updatedDescription);
    }
  };

  const clearFields = () => {
    setProductId('');
    setProductName('');
    setProductDescription(['']);
    setProductBenefits(['']);
    setProductHighlights(['']);
    setState(true);
  };

  const updateProductData = () => {
    // Replace with your API endpoint for updating a product
    axios
      .put(`http://localhost:5000/products/${productId}`, {
        productname: productName,
        productdescription: productDescription[0],
        Benefits: productBenefits,
        Highlights: productHighlights,
      })
      .then((response) => {
        console.log('Product updated:', response.data);
        alert('Product updated successfully');
        clearFields();
      })
      .catch((error) => {
        console.error('Update Error:', error);
      });
  };

  const fetchProductList1 = () => {
    axios
      .get(`http://localhost:5000/products`)
      .then((response) => {
        const filteredProducts = response.data.filter((product) =>
          product.productname.toLowerCase().includes(searchInput.toLowerCase())
        );
        setProductList(filteredProducts);
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
  };

  useEffect(() => {
    fetchProductList1();
  }, [searchInput]);

  return (
    <>
    {/* <Navbar/> */}
      {state ? (
       <div className="update_box">
         <table className="table-update">
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }} colSpan={3}>
                <input
                  type="text"
                  className="update_search"
                  placeholder="Search by Product Name"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </th>
            </tr>
            <tr>
              <th>Product ID</th>
              <th>Product Name</th>
              <th>Update</th>
            </tr>
          </thead>
          <tbody>
            {productList.map((product) => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.productname}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleUpdateClick(product.id, product.productname)}
                  >
                    Knowmore
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
       </div>
      ) : (
        ''
      )}
      <hr />
      {state ? (
        ''
      ) : (
        <>
          <label className="w-50" htmlFor="productName">
            Product Name:
          </label>
          <input
            type="text"
            className="form-control w-100 mx-auto"
            id="productName"
            name="productName"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Product Name"
          />

          <div>
            <label>Product Benefits:</label>
            {productBenefits.map((benefit, index) => (
              <div key={index}>
                <textarea
                  value={benefit}
                  className="form form-control w-100 mx-auto"
                  onChange={(e) => {
                    const updatedBenefits = [...productBenefits];
                    updatedBenefits[index] = e.target.value;
                    setProductBenefits(updatedBenefits);
                  }}
                  style={{ width: '600px' }}
                  rows="4"
                  placeholder="Product Benefit"
                />
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    const updatedBenefits = [...productBenefits];
                    updatedBenefits.splice(index, 1);
                    setProductBenefits(updatedBenefits);
                  }}
                >
                  -
                </button>
              </div>
            ))}
            <button
              className="btn btn-secondary"
              onClick={() => setProductBenefits([...productBenefits, ''])}
            >
              +
            </button>
          </div>

          <div>
            <label>Product Description:</label>
            {productDescription.map((paragraph, index) => (
              <div key={index}>
                <textarea
                  value={paragraph}
                  className="form form-control w-100 mx-auto"
                  onChange={(e) => {
                    const updatedDescription = [...productDescription];
                    updatedDescription[index] = e.target.value;
                    setProductDescription(updatedDescription);
                  }}
                  style={{ width: '600px' }}
                  rows="4"
                  placeholder="Product Description"
                />
                <button
                  className="btn btn-secondary"
                  onClick={() => handleRemoveItem('description', index)}
                >
                  -
                </button>
              </div>
            ))}
            <button className="btn btn-secondary" onClick={() => handleAddItem('description')}>
              +
            </button>
          </div>

          <div>
            <label>Product Highlights:</label>
            {productHighlights.map((highlight, index) => (
              <div key={index}>
                <textarea
                  value={highlight}
                  className="form form-control w-100 mx-auto"
                  onChange={(e) => {
                    const updatedHighlights = [...productHighlights];
                    updatedHighlights[index] = e.target.value;
                    setProductHighlights(updatedHighlights);
                  }}
                  style={{ width: '600px' }}
                  rows="4"
                  placeholder="Product Highlight"
                />
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    const updatedHighlights = [...productHighlights];
                    updatedHighlights.splice(index, 1);
                    setProductHighlights(updatedHighlights);
                  }}
                >
                  -
                </button>
              </div>
            ))}
            <button
              className="btn btn-secondary"
              onClick={() => setProductHighlights([...productHighlights, ''])}
            >
              +
            </button>
          </div>

          <button className="btn btn-success w-100 my-2" onClick={updateProductData}>
            Update
          </button>
          <button className="btn btn-secondary w-100 my-2" onClick={clearFields}>
            Cancel
          </button>
        </>
      )}
    </>
  );
}

export default UpdateProduct;

