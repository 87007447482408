import React, { useEffect, useState } from 'react';
import ReportsCard from '../ReportsCard'; // Correct relative path
import ReportsTable from '../ReportsCard/tablereports';

const CallLogReports = () => {
    const [selectedReportId, setSelectedReportId] = useState(-1);
    const [apiData, setApiData] = useState({});

    const callLogsReportData = [
        {
            id: 1,
            logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" fill="blue"/></svg>',
            title: 'Call Logs Count Report',
            apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/call_logs_count.php'
        },
        {
            id: 2,
            logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM288 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm211.3-43.3c-6.2-6.2-16.4-6.2-22.6 0L416 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z" fill="blue"/></svg>',
            title: 'Call Logs Customer Data',
            apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/callLog_customerDetails.php'
        },
        {
            id: 3,
            logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 64C0 28.7 28.7 0 64 0H256c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm64 96v64c0 17.7 14.3 32 32 32H224c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H96c-17.7 0-32 14.3-32 32zM80 352a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm24 56a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zm56-56a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm24 56a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zm56-56a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm24 56a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM128 48c-8.8 0-16 7.2-16 16s7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H128z"/ fill="blue"></svg>',
            title: 'Call Logs Details',
            apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/Clogs_data.php'
        }
    ];

    const fetchDataForReport = async (apiEndpoint) => {
        try {
            const response = await fetch(apiEndpoint);
            const data = await response.json();
            if (data.status === 'success' && data.data) {
                setApiData(prevData => ({
                    ...prevData,
                    [apiEndpoint]: data.data,
                }));
            } else {
                console.error(`Error in API response for ${apiEndpoint}:`, data.status, data.message);
            }
        } catch (error) {
            console.error(`Error fetching data for ${apiEndpoint}:`, error);
        }
    };

    useEffect(() => {
        console.log('Fetching data for all reports');
        // Fetch data for all reports when component mounts
        callLogsReportData.forEach(report => {
            fetchDataForReport(report.apiEndpoint);
        });
    }, []);

    useEffect(() => {
        // Fetch data when selectedReportId changes
        if (selectedReportId !== -1) {
            const selectedReport = callLogsReportData.find(report => report.id === selectedReportId);
            if (selectedReport) {
                fetchDataForReport(selectedReport.apiEndpoint);
            }
        }
    }, [selectedReportId]);

    const handleShowTable = (id) => {
        setSelectedReportId(id);
    };

    return (
        <div>
            {callLogsReportData.map((report) => {
                const { id, apiEndpoint } = report;
                return (
                    <React.Fragment key={id}>
                        <ReportsCard {...report} onShowTable={() => handleShowTable(id)} />
                        {selectedReportId === id && (
                            <React.Fragment>
                                {/* ReportsTable component */}
                                <ReportsTable
                                    key={apiEndpoint}
                                    apiEndpoints={[apiEndpoint]}
                                    data={apiData}
                                    show={selectedReportId === id}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
}

export default CallLogReports;
