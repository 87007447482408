const AgentCalls = () => {
    return ( 
        <>
        <div className="main-container">
            <div className="sub-container">
                <h4>AgentCalls Page</h4>
            </div>
        </div>
        </>
     );
}
 
export default AgentCalls;