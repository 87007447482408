import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProductDetails.css';
import categoriesData from '../Data/categories.json';

const AddProvider = () => {
    const [productData, setProductData] = useState({
        product_type_name: '',
        headline: '',
        description: '',
        purpose_of_the_plan: '',
        benefits: [''],
        highlights: [''],
        products: [{
            product_id: '',
        }],
    });
    const [alertMessage, setAlertMessage] = useState('');
    const [filtered, setFiltered] = useState([]);
    const [products, setProducts] = useState([[]]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    "https://api.indiainsurancedeals.com/v1/common/readCustomQuery.php",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            propname: "iid_product",
                            query_id: "iid_provider_products",
                        }),
                    }
                );

                const result = await response.json();

                const uniqueData = result.data.reduce((acc, current) => {
                    const existing = acc.find(
                        (item) => item.provider_name === current.provider_name
                    );
                    if (!existing) {
                        return [...acc, current];
                    }
                    return acc;
                }, []);

                setFiltered(uniqueData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    const providerHandler = async (event, index) => {
        const { value } = event.target;
        const url =
            "https://api.indiainsurancedeals.com/v1/common/readSelected.php";
        const params = {
            tbname: "product",
            propname: "product",
            cols: "id,product_name,category_id",
            filters: `provider_id = ${value}`
        };
        try {
            const response = await axios.post(url, JSON.stringify(params), {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            // Set the fetched product data to the state
            const updatedProducts = [...products];
            updatedProducts[index] = response.data.data;
            setProducts(updatedProducts);

            // Ensure that the product ID is correctly set in the product data
            const updatedProductsData = [...productData.products];
            updatedProductsData[index] = { ...updatedProductsData[index], provider_id: value };
            setProductData({
                ...productData,
                products: updatedProductsData,
            });
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    const productHandler = (e, index, providerId) => { // Accept providerId as an argument
        console.log("Selected Provider ID:", providerId); // Log the selected provider ID
        const updatedProductsData = [...productData.products];
        updatedProductsData[index] = { ...updatedProductsData[index], product_id: e.target.value };
        setProductData({
            ...productData,
            products: updatedProductsData,
        });
    };

    const handleInputChange = (e, field, index) => {
        if (field === 'benefits' || field === 'highlights' || field === 'products') {
            const updatedField = [...productData[field]];
            updatedField[index] = e.target.value;
            setProductData({
                ...productData,
                [field]: updatedField,
            });
        } else {
            setProductData({
                ...productData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleAddParagraph = (field) => {
        setProductData({
            ...productData,
            [field]: [...productData[field], ''],
        });
    };

    const handleRemoveParagraph = (field, index) => {
        const updatedField = productData[field].filter((_, i) => i !== index);
        setProductData({
            ...productData,
            [field]: updatedField,
        });
    };

    const addProductToAPI = () => {
        if (!productData.product_type_name ||
            !productData.headline ||
            !productData.description ||
            !productData.purpose_of_the_plan ||
            productData.benefits.some(benefit => !benefit) ||
            productData.highlights.some(highlight => !highlight) ||
            productData.products.some(product => !product.product_id)) {
            alert('Please fill in all the fields.');
            return;
        }
    
        console.log("Product Data to be added:", productData);
        axios
            .post('https://api.indiainsurancedeals.com/v1/common/productInsertMaping.php', productData)
            .then((response) => {
                alert('Provider added successfully.');
                console.log('Product added:', response);

                setProductData({
                    product_type_name: '',
                    headline: '',
                    description: '',
                    purpose_of_the_plan: '',
                    benefits: [''],
                    highlights: [''],
                    products: [''],
                });
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server error:', error.response.data);
                } else if (error.request) {
                    console.error('No response from server');
                } else {
                    console.error('Error setting up the request:', error.message);
                }
                setAlertMessage('Error adding product. Please check the data and try again.');
            });
    };

    const handleReset = () => {
        setProductData({
            product_type_name: '',
            headline: '',
            description: '',
            purpose_of_the_plan: '',
            benefits: [''],
            highlights: [''],
            products: ['']
        });
        setAlertMessage('');
    };
    const categories = categoriesData.categories;

    return (
        <>
            <div className='container_type'>
                <div className='innerBox'>
                    <div className='innerdiv'>
                        <label>Provider name</label>
                        <input
                            type="text"
                            name="product_type_name"
                            placeholder="Enter Provider Name"
                            value={productData.product_type_name}
                            onChange={(e) => handleInputChange(e, 'product_type_name')}
                        />
                        <div className='select-container'>
                            <label>Select Category</label>
                            <select
                                value={productData.category_id}
                                onChange={(e) => setProductData({ ...productData, category_id: e.target.value })}
                            >
                                <option value="">Select a category</option>
                                {categories.map(category => (
                                    <option key={category.id} value={category.id}>
                                        {category.category_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <label>Headline</label>
                        <input
                            type="text"
                            name="headline"
                            placeholder="Headline"
                            value={productData.headline}
                            onChange={(e) => handleInputChange(e, 'headline')}
                        />
                        <label>Purpose of the plan</label>
                        <input
                            type="text"
                            name="purpose_of_the_plan"
                            placeholder="Purpose of the plan"
                            value={productData.purpose_of_the_plan}
                            onChange={(e) => handleInputChange(e, 'purpose_of_the_plan')}
                        />
                        <label>Description</label>
                        <textarea
                            name="description"
                            placeholder="Description"
                            value={productData.description}
                            onChange={(e) => handleInputChange(e, 'description')}
                        ></textarea>
                        <div className='all_labels'>
                            <div>
                                <label>benefits</label>
                                {productData.benefits.map((benefit, index) => (
                                    <div key={index}>
                                        <textarea
                                            name={index}
                                            placeholder="Enter Provider Benefit"
                                            value={benefit}
                                            onChange={(e) => handleInputChange(e, 'benefits', index)}
                                        ></textarea>
                                        {index > 0 && (
                                            <button onClick={() => handleRemoveParagraph('benefits', index)}>
                                                -
                                            </button>
                                        )}
                                        <button onClick={() => handleAddParagraph('benefits')}>
                                            +
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <label>highlights</label>
                                {productData.highlights.map((highlight, index) => (
                                    <div key={index}>
                                        <textarea
                                            name={index}
                                            placeholder="Enter Provider Highlight"
                                            value={highlight}
                                            onChange={(e) => handleInputChange(e, 'highlights', index)}
                                        ></textarea>
                                        {index > 0 && (
                                            <button onClick={() => handleRemoveParagraph('highlights', index)}>
                                                -
                                            </button>
                                        )}
                                        <button onClick={() => handleAddParagraph('highlights')}>
                                            +
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <div>
                                {productData.products.map((product, index) => (
                                    <div key={index}>
                                        <div className='select-container'>
                                            <div>
                                                <label>Select Provider</label>
                                                <select onChange={(e) => providerHandler(e, index)}>
                                                    <option value="">Select Provider</option>
                                                    {filtered?.map((provider) => (
                                                        <option key={provider?.provider_id} value={provider?.provider_id}>
                                                            {provider ? provider.provider_name : ""}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
                                                <label>Select Product</label>
                                                <select onChange={(e) => productHandler(e, index, e.target.value)} value={product.product_id}>
                                                    <option value="">Select Product</option>
                                                    {products[index]?.map((productOption) => (
                                                        <option key={productOption.id} value={productOption.id}>
                                                            {productOption.product_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {index > 0 && (
                                            <button onClick={() => handleRemoveParagraph('products', index)}>
                                                -
                                            </button>
                                        )}
                                        <button onClick={() => handleAddParagraph('products')}>
                                            +
                                        </button>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <button onClick={handleReset}>Reset</button>
                        <button onClick={addProductToAPI}>
                            Add Product
                        </button>
                    </div>
                </div>
            </div>
            {alertMessage && <div className="alert">{alertMessage}</div>}
        </>
    );
};

export default AddProvider;

