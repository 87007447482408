import React from 'react';
import './style.css';

const ReportsTable = ({ apiEndpoints, agentName, data, loading }) => {
  

  const renderTableHeaders = (rowData) => {
    if (!rowData || rowData.length === 0) {
      return null;
    }



    // Extracting column names from the first row of data
    const columns = Object.keys(rowData[0]);

    return columns.map((column) => (
      <th key={column}>{column}</th>
    ));
  };

  const renderTableRows = (rowData) => {
    if (!rowData || rowData.length === 0) {
      return null;
    }

    return rowData.map((row, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        {Object.values(row).map((cell, idx) => (
          <td key={idx}>{cell}</td>
        ))}
      </tr>
    ));
  };

  return (
    <>
      {apiEndpoints && Array.isArray(apiEndpoints) && apiEndpoints.map((apiEndpoint) => (
        <div key={apiEndpoint}>
          {/* <h3>Data for API: {apiEndpoint}</h3> */}
          {data && data[apiEndpoint] && Array.isArray(data[apiEndpoint]) ? (
            <table>
              <thead>
                <tr>
                  <th>SL NO</th>
                  {renderTableHeaders(data[apiEndpoint])}
                </tr>
              </thead>
              <tbody>
                {renderTableRows(data[apiEndpoint])}
              </tbody>
            </table>
          ) : (
            <p>No data available for this API.</p>
          )}
        </div>
      ))}
    </>
  );
};

export default ReportsTable;



// import React from 'react';

// const ReportsTable = ({ apiEndpoints, agentName, data, loading, reportId, filterParams, editMode }) => {
//   const applyFilters = () => {
//     // Your filter logic based on reportId and filterParams
//     // This function should filter the data array based on the provided parameters
//     // You can use filterParams and reportId to determine which filters to apply
//     // Return the filtered data array
//     return data;
//   };

//   const renderTableHeaders = (rowData) => {
//     if (!rowData || rowData.length === 0) {
//       return null;
//     }

//     // Extracting column names from the first row of data
//     const columns = Object.keys(rowData[0]);

//     return columns.map((column) => (
//       <th key={column}>{column}</th>
//     ));
//   };

//   const handleSave = (rowData) => {
//     // Handle save logic here...
//     console.log('Saved data:', rowData);
//     // You can perform additional actions here, such as sending the modified data to the server
//   };

//   const renderTableRows = (rowData) => {
//     if (!rowData || rowData.length === 0) {
//       return null;
//     }

//     return rowData.map((row, index) => (
//       <tr key={index}>
//         <td>{index + 1}</td>
//         {Object.values(row).map(([key, value], idx) => (
//           <td key={idx}>{editMode ? <input type="text" defaultValue={value} /> : value}</td>
//         ))}
//         {editMode && <td><button onClick={() => handleSave(row)}>Save</button></td>}
//       </tr>
//     ));
//   };

//   const filteredData = applyFilters(); // Apply filters to data based on reportId and filterParams

//   return (
//     <>
//       {apiEndpoints && Array.isArray(apiEndpoints) && apiEndpoints.map((apiEndpoint) => (
//         <div key={apiEndpoint}>
//           {/* <h3>Data for API: {apiEndpoint}</h3> */}
//           {filteredData && filteredData[apiEndpoint] && Array.isArray(filteredData[apiEndpoint]) ? (
//             <table>
//               <thead>
//                 <tr>
//                   <th>SL NO</th>
//                   {renderTableHeaders(filteredData[apiEndpoint])}
//                 </tr>
//               </thead>
//               <tbody>
//                 {renderTableRows(filteredData[apiEndpoint])}
//               </tbody>
//             </table>
//           ) : (
//             <p>No data available for this API.</p>
//           )}
//         </div>
//       ))}
//     </>
//   );
// };

// export default ReportsTable;

// import React, { useState } from 'react';

// const ReportsTable = ({ apiEndpoints, agentName, data, loading, reportId, filterParams, editMode }) => {
//   const [editedData, setEditedData] = useState(null);

//   const applyFilters = () => {
//     // Your filter logic based on reportId and filterParams
//     // This function should filter the data array based on the provided parameters
//     // You can use filterParams and reportId to determine which filters to apply
//     // Return the filtered data array
//     return data;
//   };

//   const handleInputChange = (event, rowIndex, key) => {
//     const newData = [...editedData];
//     newData[rowIndex][key] = event.target.value;
//     setEditedData(newData);
//   };

//   const handleSave = () => {
//     // Handle save logic here...
//     console.log('Saved data:', editedData);
//     // You can perform additional actions here, such as sending the modified data to the server
//   };

//   const renderTableHeaders = (rowData) => {
//     if (!rowData || rowData.length === 0) {
//       return null;
//     }

//     // Extracting column names from the first row of data
//     const columns = Object.keys(rowData[0]);

//     return columns.map((column) => (
//       <th key={column}>{column}</th>
//     ));
//   };

//   const renderTableRows = (rowData) => {
//     if (!rowData || rowData.length === 0) {
//       return null;
//     }

//     return rowData.map((row, index) => (
//       <tr key={index}>
//         <td>{index + 1}</td>
//         {Object.entries(row).map(([key, value]) => (
//           <td key={key}>
//             {editMode ? (
//               <input
//                 type="text"
//                 value={editedData ? editedData[index][key] : value}
//                 onChange={(event) => handleInputChange(event, index, key)}
//               />
//             ) : (
//               value
//             )}
//           </td>
//         ))}
//       </tr>
//     ));
//   };

//   const filteredData = applyFilters(); // Apply filters to data based on reportId and filterParams

//   // If in edit mode, initialize editedData state with the original data
//   useState(() => {
//     if (editMode) {
//       setEditedData(data);
//     }
//   }, [editMode, data]);

//   return (
//     <>
//       {apiEndpoints && Array.isArray(apiEndpoints) && apiEndpoints.map((apiEndpoint) => (
//         <div key={apiEndpoint}>
//           {/* <h3>Data for API: {apiEndpoint}</h3> */}
//           {filteredData && filteredData[apiEndpoint] && Array.isArray(filteredData[apiEndpoint]) ? (
//             <table>
//               <thead>
//                 <tr>
//                   <th>SL NO</th>
//                   {renderTableHeaders(filteredData[apiEndpoint])}
//                 </tr>
//               </thead>
//               <tbody>
//                 {renderTableRows(filteredData[apiEndpoint])}
//               </tbody>
//             </table>
//           ) : (
//             <p>No data available for this API.</p>
//           )}
//           {editMode && (
//             <button onClick={handleSave}>Save</button>
//           )}
//         </div>
//       ))}
//     </>
//   );
// };

// export default ReportsTable;




