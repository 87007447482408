import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './style.css';
import logo from '../../Assets/admin_logo.png';

const sections = [
    { id: "home", label: "Home", url: "/" },
    { id: "reports", label: "Reports", url: "/reports" },
    { id: "highlights", label: "Highlights", url: "/highlights" },
    { id: "news", label: "News", url: "/news" },
    {
        id: "products", label: "Products", url: "/addproducts", dropdownItems: [
            { label: "Add Product", url: "/addproducts" },
            { label: "Update Product", url: "/updateproducts" },
            { label: "Delete Product", url: "/deleteproducts" },
            { label: "Product Type", url: "/productdetails"}
        ]
    },
    {
        id: "providers", label: "Providers", url: "/addproviders", dropdownItems: [
            { label: "Add Provider", url: "/addprovider" },
            { label: "Update Provider", url: "/updateprovider" },
            { label: "Delete Provider", url: "/deleteprovider" }
        ]
    },
    {
        id: "management", label: "Management", url: "/management", dropdownItems: [
            { label: "Employee", url: "/employee" },
            { label: "Customer", url: "/customer" },
            { label: "Agent Calls", url: "/agentcalls" }
        ]
    }
];

const Navbar = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (window.innerWidth > 680) {
                setIsMobileMenuOpen(false); // Close the mobile menu if screen size is bigger than 680 pixels
            }
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div className="nav-container">
            <div className="logo-container">
                <img src={logo} alt="Logo" className="logo-img" />
            </div>
            {windowWidth <= 680 && (
                <div className="menu-button" onClick={toggleMobileMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512">
                        <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" fill="blue"/>
                    </svg>
                </div>
            )}
           <div className={`links-container ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                <div className="nav-subs">
                    {sections.map((section, index) => (
                        <div key={index} className="nav-link">
                            {section.dropdownItems ? (
                                <div className="dropdown">
                                    <Link to={section.url} id={`${section.id}-link`} className="links">
                                        {section.label}
                                    </Link>
                                    <div className="dropdown-content">
                                        {section.dropdownItems.map((item, idx) => (
                                            <Link key={idx} to={item.url} className="links">{item.label}</Link>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <Link to={section.url} className="links">{section.label}</Link>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Navbar;







