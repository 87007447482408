import React, { useEffect, useState } from 'react';
import ReportsCard from '../ReportsCard'; // Correct relative path
import ReportsTable from '../ReportsCard/tablereports';
import DatePicker from 'react-datepicker'; // Import DatePicker component
import 'react-datepicker/dist/react-datepicker.css'; // Import DatePicker styles
import './style.css';


const AgentReports = () => {
    const [selectedReportId, setSelectedReportId] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [apiData, setApiData] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [agentName, setAgentName] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);


    const agentReportsData = [
        {
            id: 1,
            logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" fill="blue"/></svg>',
            title: 'Dialing Report',
            apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/dialingReport.php'
        },
        {
            id: 2,
            logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z" fill="blue"/></svg>',
            title: 'Appointment Report',
            apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/appointmentReports.php'
        },
        {
            id: 3,
            logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 256h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm256-32H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16z" fill="blue"/></svg>',
            title: 'Dial Status Report Current Day By Agent',
            apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/dial_status_report.php'
        },
        {
            id: 4,
            logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" fill="blue"/></svg>',
            title: 'Dial Status Report By Date',
            apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/dial_status_report_by_date.php'
          },
          {
            id: 5,
            logo: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM112 192H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" fill="blue"/></svg>',
            title: 'Dial Status Report',
            apiEndpoint: 'https://api.indiainsurancedeals.com/v1/common/dial_status_report_count.php'
          }

    ];
    const fetchDataForReport = async (apiEndpoint) => {
        try {
            const response = await fetch(apiEndpoint);
            const data = await response.json();
            if (data.status === 'success' && data.data) {
                setApiData(prevData => ({
                    ...prevData,
                    [apiEndpoint]: data.data,
                }));
            } else {
                console.error(`Error in API response for ${apiEndpoint}:`, data.status, data.message);
            }
        } catch (error) {
            console.error(`Error fetching data for ${apiEndpoint}:`, error);
        }
    };


    const filterDataByNameAndDate = () => {
        const filteredData = {};

        for (const report of agentReportsData) {
            const { apiEndpoint } = report;
            const data = apiData[apiEndpoint];

            if (data) {
                let filtered = [...data]; // Create a copy of the data array to avoid mutating the original

                // Filter based on agent name
                if (agentName.trim() !== '') {
                    filtered = filtered.filter(
                        (item) =>
                            item.first_name &&
                            item.first_name.toLowerCase().includes(agentName.toLowerCase())
                    );
                }

                // Filter based on selected date
                if (selectedDate) {
                    filtered = filtered.filter(
                        (item) =>
                            item.call_date &&
                            new Date(item.call_date).toDateString() ===
                            selectedDate.toDateString()
                    );
                }

                filteredData[apiEndpoint] = filtered;
            } else {
                console.error(`No data found for API endpoint: ${apiEndpoint}`);
            }
        }

        setFilteredData(filteredData);
    };

    useEffect(() => {
        // Fetch data when selectedReportId changes
        if (selectedReportId !== -1) {
            const selectedReport = agentReportsData.find(report => report.id === selectedReportId);
            if (selectedReport) {
                fetchDataForReport(selectedReport.apiEndpoint);
            }
        }
    }, [selectedReportId]);

    useEffect(() => {
        // Filter data when agentName, selectedDate, or apiData changes
        filterDataByNameAndDate();
    }, [agentName, selectedDate, apiData]);
    

    const handleShowTable = (id) => {
        setSelectedReportId(id);
    };

    return (
        <div className="reportsContainer">
            <div className='searchContainer'>
                <input
                    className='searchName'
                    type="text"
                    placeholder="Enter Agent's Name"
                    value={agentName}
                    onChange={(e) => setAgentName(e.target.value)}
                />
                <DatePicker // Date picker component
                    className='datePicker'
                    selected={selectedDate}
                    onChange={call_date => setSelectedDate(call_date)}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select Date"
                />
                <svg xmlns="http://www.w3.org/2000/svg" className='searchIcon' height="15" viewBox="0 0 512 512">
                    <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" className='dateIcon' height="15" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10c-7.2 24-24 40-48 40h-32c-26.5 0-48-21.5-48-48V64zM384 224c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v32zm32 160c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32z" /></svg>
            </div>
            <div className="reportsContainer">
                {agentReportsData.map((report) => {
                    const { id, apiEndpoint } = report;
                    return (
                        <React.Fragment key={id}>
                            <ReportsCard {...report} onShowTable={() => handleShowTable(id)} />
                            {selectedReportId === id && (
                                <React.Fragment>
                                    {/* ReportsTable component */}
                                    <ReportsTable
                                        key={apiEndpoint}
                                        apiEndpoints={[apiEndpoint]}
                                        agentName={agentName}
                                        data={filteredData}
                                        show={selectedReportId === id}
                                        loading={loading}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
            {loading ? (
            <div className="loading">
                {/* Add loading animation or text here */}
                Loading...
            </div>
        ) : null}
        </div>
    );
}

export default AgentReports;
