import React from 'react';
// import { useNavigate } from "react-router-dom";
import { Routes, Route} from "react-router-dom";

import Loginpage from "../Login";
import ReportsComponent from "../Reports";
import Highlights from '../Highlights';
import News from '../News';
import Products from '../Products/Products';
import AddProduct from '../Products/AddProduct';
import DeleteProduct from '../Products/DeleteProduct';
import UpdateProduct from "../Products/UpdateProduct";
import ReportsTable from "../../Components/ReportsCard/tablereports";
import AddProvider from '../Provider/AddProvider';
import Navbar from "../../Components/Navbar";
import UpdateProvider from '../Provider/UpdateProvider';
import DeleteProvider from '../Provider/DeleteProvider';
import Employee from '../Management/Employee';
import Customer from '../Management/Customer';
import Agentcalls from '../Management/AgentsCalls';
import ProductDetails from '../Products/ProductDetails';
import AgentReports from '../../Components/AgentReports';
import CallLogReports from '../../Components/CallLogReports';
import TeleCustomerReport from '../../Components/TeleCustomerReports';

const ReportsHome = () => {
  // const [userCredentials, setUserCredentials] = useState(false);

  // useEffect(() => {
  //   const credentials = async () => {
  //     setUserCredentials(sessionStorage.getItem("credentials"));
  //   };
  //   credentials();
  // }, []);

  // const navigateTo = useNavigate();

  // if (!userCredentials) {
  //   navigateTo("/");
  // }

  return ( 
    <>
      <Navbar/>
      <Routes>
        <Route path='/loginpage' element={<Loginpage/>}/>
        <Route path='/products' element={<Products/>}/>
        <Route path='/news' element={<News/>}/>
        <Route path="/highlights" element={<Highlights/>}/>
        <Route path="/reports" element={<ReportsComponent/>}/>
        <Route path="/ReportsTable" element={<ReportsTable/>}/>
        <Route path="/addproducts" element={<AddProduct/>}/>
        <Route path="/deleteproducts" element={<DeleteProduct/>}/>
        <Route path="/updateproducts" element={<UpdateProduct/>}/>
        <Route path='/addprovider' element={<AddProvider/>}/>
        <Route path='/updateprovider' element={<UpdateProvider/>}/>
        <Route path='/deleteprovider' element={<DeleteProvider/>}/>
        <Route path='/employee' element={<Employee/>}/>
        <Route path='/customer' element={<Customer/>}/>
        <Route path='/agentcalls' element={<Agentcalls/>}/>
        <Route path='/productdetails' element={<ProductDetails/>}/>
        <Route path='/agentreports' element={<AgentReports/>}/>
        <Route path='/calllogreports' element={<CallLogReports/>}/>
        <Route path='/telecustomerreports' element={<TeleCustomerReport/>}/>
      </Routes>
    </>
  );
}
 
export default ReportsHome;
