import React, { useState } from 'react';
import './products.css';
import AddProduct from './AddProduct';
import UpdateProduct from './UpdateProduct';
import DeleteProduct from './DeleteProduct';
import ProductDetails from './ProductDetails';
import Navbar from '../../Components/Navbar';

const Products = () => {
  const [activeOption, setActiveOption] = useState('add');

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  return (
   <>
   {/* <Navbar/> */}
    <div className="products_box">
      {/* <div className="toggle-bar">
        <button
          className={`toggle-button ${activeOption === 'add' ? 'active' : ''}`}
          onClick={() => handleOptionClick('add')}
        >
          Add Product
        </button>
        <button
          className={`toggle-button ${activeOption === 'update' ? 'active' : ''}`}
          onClick={() => handleOptionClick('update')}
        >
          Update Product
        </button>
        <button
          className={`toggle-button ${activeOption === 'delete' ? 'active' : ''}`}
          onClick={() => handleOptionClick('delete')}
        >
          Delete Product
        </button>
      </div> */}
      <div className="displaybox">
        {activeOption === 'add' && <AddProduct />}
        {activeOption === 'update' && <UpdateProduct />}
        {activeOption === 'delete' && <DeleteProduct />}
        {activeOption === 'info' && <ProductDetails/>}
      </div>
    </div>
 
  </>
  );
};

export default Products;
