import React, { useState, useEffect } from 'react';
import './Employee.css';
import AddEmployee from './AddEmployee';

const Employee = () => {
    const [employees, setEmployees] = useState([]);
    const [selectedPage, setSelectedPage] = useState("Pie Chart");
    const [loading, setLoading] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        // Fetch employee data from API
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.indiainsurancedeals.com/v1/common/readall.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        tbname: "employee",
                        propname: "employee"
                    })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data && data.status === "success") {
                    setEmployees(data.data);
                    setLoading(false);
                } else {
                    console.error('Error fetching employee data:', data);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching employee data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handlePageSelect = (page) => {
        setSelectedPage(page);
        setSidebarOpen(false);
    };

    return (
        <div className="employee-page">
            <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
                <h2>Employee Pages</h2>
                <ul>
                    <li className={selectedPage === "Add Employee" ? "active" : ""} onClick={() => handlePageSelect("Add Employee")}>
                        Add Employee
                    </li>
                    <li className={selectedPage === "Graphs" ? "active" : ""} onClick={() => handlePageSelect("Graphs")}>
                        Graphs
                    </li>
                </ul>
            </div>
            <div className="menu-icon" onClick={() => setSidebarOpen(!sidebarOpen)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" fill='whitesmoke'/></svg>
            </div>
            <div className="employee-container">
                {loading ? (
                    <div className="spinner"></div>
                ) : selectedPage === "Add Employee" ? (
                    <AddEmployee />
                ) : employees.length > 0 ? (
                    employees.map((employee, index) => (
                        <div key={index} className="employee-card">
                            <div className="employee-profile">
                                <img src={`https://i.pravatar.cc/150?img=${index}`} alt="Employee Avatar" className="avatar" />
                                <h2>{`${employee.first_name} ${employee.last_name}`}</h2>
                                <p><strong>Email:</strong> {employee.email_id}</p>
                                <p><strong>Department:</strong> {employee.department}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No employee data available.</p>
                )}
            </div>
        </div>
    );
}

export default Employee;

