import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./DeleteProduct.css"
import Navbar from '../../Components/Navbar';

const Adminpage = () => {
  const [deleteProduct, setDeleteProduct] = useState(true);
  const [productData, setProductData] = useState({
    id: '',
    productName: '',
  });
  const [productDataArray, setProductDataArray] = useState([]);

  useEffect(() => {
    // Load the JSON data here from your file or API
    axios.get('http://localhost:5000/products') // Replace with your API endpoint
      .then((response) => {
        if (Array.isArray(response.data)) {
          setProductDataArray(response.data);
        } else {
          console.error('Invalid product data format:', response.data);
        }
      })
      .catch((error) => {
        console.error('Error loading product data:', error);
      });
  }, []);

  const handleButtonClick = (action) => {
    setDeleteProduct(false);

    if (action === 'delete') {
      setDeleteProduct(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const deleteProductInAPI = () => {
    const isConfirmed = window.confirm(`Are you sure you want to delete ${productData.productName} (ID: ${productData.id})? it cannot be undone once deleted`);

    if (!isConfirmed) {
      return;
    }

    axios
      .delete(`http://localhost:5000/products/${productData.id}`) // Replace with your API endpoint
      .then((response) => {
        // Display an alert with the deleted product information
        window.alert(`${productData.productName} (ID: ${productData.id}) has been deleted.`);

        // Clear the form and update the product list
        setProductData({
          id: '',
          productName: '',
        });

        setProductDataArray((prevData) =>
          prevData.filter((product) => product.id !== productData.id)
        );
      })
      .catch((error) => {
        console.error('Error deleting product:', error);
      });
  };

  return (
    <>
    {/* <Navbar/> */}
      <div className="delete_box">
        <div className="inner_dl_box">
          {deleteProduct ? (
              <div className="dl_container">
                <input
                  className="form"
                  type="number"
                  name="id"
                  placeholder="Enter product id"
                  onChange={handleInputChange}
                  value={productData.id}
                />
                <input
                  className="form"
                  type="text"
                  name="productName"
                  placeholder="Enter product Name"
                  onChange={handleInputChange}
                  value={productData.productName}
                />
                <button className="deletebtn" onClick={deleteProductInAPI}>
                  Delete Product
                </button>
              </div>
            ) : null}
       
        </div>
      </div>
    </>
  );
};

export default Adminpage;





