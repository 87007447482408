import { BrowserRouter } from 'react-router-dom';
import './App.css';
// import { BrowserRouter, Routes, Route} from "react-router-dom";
// import Navbar from './Components/Navbar';
// import ReportsComponent from "./Pages/Reports";
// import Highlights from './Pages/Highlights';
// import ReportsTable from "./Components/ReportsCard/tablereports";
// import News from './Pages/News';
// import Products from './Pages/Products/Products';
import Loginpage from './Pages/Login';
// import ReportsHome from './Pages/Home';
// import AddProduct from './Pages/Products/AddProduct';
// import DeleteProduct from './Pages/Products/DeleteProduct';
// import UpdateProduct from './Pages/Products/UpdateProduct';


function App() {
  return (
    <>
      <BrowserRouter>
        <Loginpage />
      </BrowserRouter>

    </>
  );
}

export default App;
