import React, { useState } from 'react';
import './style.css';

const ReportsCard = ({ id, title, logo, onShowTable, }) => {
  const [selectedFilter, setSelectedFilter] = useState('');

  const handleViewMoreClick = () => {
    onShowTable(id, selectedFilter);
  };

 

  return (
    <div className="cards">
      <div className="logo" dangerouslySetInnerHTML={{ __html: logo }}></div>
      <div className="card_title" id='c_title'>{title}</div>
      <div className="button">
        <button onClick={handleViewMoreClick}>View More</button>
      </div>
    </div>
  );
};


export default ReportsCard;
