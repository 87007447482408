import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './telecustomer.css';

const API_URLS = {
    api1: 'https://api.indiainsurancedeals.com/v1/common/Tele_Customer_Reports/current_status_report.php?current_status=',
    api2: 'https://api.indiainsurancedeals.com/v1/common/Tele_Customer_Reports/customer_status_report.php?customer_status=',
    api3_base: 'https://api.indiainsurancedeals.com/v1/common/Tele_Customer_Reports/rm_name_reports.php?rm_id=',
    api4: 'https://api.indiainsurancedeals.com/v1/common/Tele_Customer_Reports/renewal_date_reports.php'
};

const FILTERS = {
    api1: [
        'intrested',
        'cust_rejected',
        'underwriter_rejected',
        'documents_provided',
        'payment_pending',
        'payment_paid',
        'rm_uploading_documents',
        'proposal_generated',
        'underwriter_review',
        'medical_raised',
        'financial_documents_raised',
        'underwriter_approved',
        'policy_issued'
    ],
    api2: [
        'active',
        'inactive',
        'pending',
        'suspended',
        'closed'
    ],
    // Assuming some filters for the third API
    api3: [
        // Define your filters here
    ]
};

function App() {
    const [api1Filter, setApi1Filter] = useState('');
    const [api2Filter, setApi2Filter] = useState('');
    const [api3Filter, setApi3Filter] = useState('');
    const [api1Data, setApi1Data] = useState([]);
    const [api2Data, setApi2Data] = useState([]);
    const [api3Data, setApi3Data] = useState([]);
    const [api4Data, setApi4Data] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);

            // Fetch data for API 1
            const response1 = await axios.get(API_URLS.api1);
            setApi1Data(response1.data.data);

            // Fetch data for API 2
            const response2 = await axios.get(API_URLS.api2);
            setApi2Data(response2.data.data);

            // Fetch data for API 3
            // Assuming rmId is hardcoded as 24
            const rmId = 24;
            const response3 = await axios.get(API_URLS.api3_base + rmId);
            setApi3Data(response3.data.data);

            const response4 = await axios.get(API_URLS.api4);
            console.log("API 4 Data ", response4.data);
            setApi4Data(response4.data.data);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data: ', error);
            setError('An error occurred while fetching data.');
            setLoading(false);
        }
    };

    const handleApi1FilterChange = (event) => {
        setApi1Filter(event.target.value);
    };

    const handleApi2FilterChange = (event) => {
        setApi2Filter(event.target.value);
    };

    const handleApi3FilterChange = (event) => {
        setApi3Filter(event.target.value);
    };

    const filteredApi1Data = api1Data.filter(customer => api1Filter ? customer.current_status === api1Filter : true);
    const filteredApi2Data = api2Data.filter(customer => api2Filter ? customer.customer_status === api2Filter : true);
    const filteredApi3Data = api3Data.filter(customer => api3Filter ? customer.rm_id === api3Filter : true);
    console.log("Api4 state data", api4Data);
    return (
        <div className="container">
            <div className="current_status_card">
                <div className="current_heads heads">
                    <div className="logo">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" fill="blue" /></svg>
                    </div>
                    <h4 className="heading">Current Status Reports</h4>
                    <h5 className="heading"></h5>
                    <select className="select" value={api1Filter} onChange={handleApi1FilterChange}>
                        <option value="">Select Filter</option>
                        {FILTERS.api1.map((filterOption, index) => (
                            <option key={index} value={filterOption}>{filterOption}</option>
                        ))}
                    </select>
                </div>


                <div className="card_content">

                    {loading && <p className="loading"></p>}
                    {error && <p className="error">{error}</p>}

                    <table className="table_current">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Product</th>
                                <th>Premium</th>
                                <th>Current Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredApi1Data.map(customer => (
                                <tr key={customer.id}>
                                    <td>{customer.customer_name}</td>
                                    <td>{customer.product_name}</td>
                                    <td>{customer.premium}</td>
                                    <td>{customer.current_status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>



            <div className="customer_status_card">
                <div className="customer_heads heads">
                    <div className="logo">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM112 192H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" fill="blue" /></svg>
                    </div>
                    <h1 className="heading">Customer Status Reports</h1>
                    <h1 className="heading"></h1>
                    <select className="select" value={api2Filter} onChange={handleApi2FilterChange}>
                        <option value="">Select Filter</option>
                        {FILTERS.api2.map((filterOption, index) => (
                            <option key={index} value={filterOption}>{filterOption}</option>
                        ))}
                    </select>
                </div>

                <div className="card_content">

                    {loading && <p className="loading"></p>}
                    {error && <p className="error">{error}</p>}

                    <table className="table_customer">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Product</th>
                                <th>Premium</th>
                                <th>Customer Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            {filteredApi2Data.map(customer => (
                                <tr key={customer.id}>
                                    <td>{customer.customer_name}</td>
                                    <td>{customer.product_name}</td>
                                    <td>{customer.premium}</td>
                                    <td>{customer.customer_status}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>



            <div className="rm_name_reports_card">
                <div className="rm_heads heads">
                    <div className="logo">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M96 128a128 128 0 1 0 256 0A128 128 0 1 0 96 128zm94.5 200.2l18.6 31L175.8 483.1l-36-146.9c-2-8.1-9.8-13.4-17.9-11.3C51.9 342.4 0 405.8 0 481.3c0 17 13.8 30.7 30.7 30.7H162.5c0 0 0 0 .1 0H168 280h5.5c0 0 0 0 .1 0H417.3c17 0 30.7-13.8 30.7-30.7c0-75.5-51.9-138.9-121.9-156.4c-8.1-2-15.9 3.3-17.9 11.3l-36 146.9L238.9 359.2l18.6-31c6.4-10.7-1.3-24.2-13.7-24.2H224 204.3c-12.4 0-20.1 13.6-13.7 24.2z" fill="blue" /></svg>
                    </div>
                    <h1 className="heading">RM Name Reports</h1>
                    <h1 className="heading"></h1>
                    <select className="select" value={api3Filter} onChange={handleApi3FilterChange}>
                        <option value="">Select Filter</option>
                        {FILTERS.api3.map((filterOption, index) => (
                            <option key={index} value={filterOption}>{filterOption}</option>
                        ))}
                    </select>
                </div>

                <div className="card_content">

                    {loading && <p className="loading"></p>}
                    {error && <p className="error">{error}</p>}

                    <table className="table_rm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Product</th>
                                <th>Premium</th>
                                <th>RM ID</th>
                                <th>Commission</th>
                                <th>Commission Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredApi3Data.map(customer => (
                                <tr key={customer.id}>
                                    <td>{customer.customer_name}</td>
                                    <td>{customer.product_name}</td>
                                    <td>{customer.premium}</td>
                                    <td>{customer.rm_id}</td>
                                    <td>{customer.commission}</td>
                                    <td>{customer.commission_status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="renewal_reports_card">
                <div className="renewal_heads heads">
                    <div className="logo">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm80 64c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80z" fill='blue'/></svg>
                    </div>
                    <h1 className="heading">Renewal Date Reports</h1>
                    <h1 className="heading"></h1>
                    <select className="select" value={api2Filter} onChange={handleApi2FilterChange}>
                        <option value="">Select Filter</option>
                        {FILTERS.api2.map((filterOption, index) => (
                            <option key={index} value={filterOption}>{filterOption}</option>
                        ))}
                    </select>
                </div>

                <div className="card_content">
                    {/* Display loading and error messages if needed */}
                    {loading && <p className="loading"></p>}
                    {error && <p className="error">{error}</p>}

                    {/* Display API 4 data */}
                    <table className="table_renewal">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Product</th>
                                <th>Premium</th>
                                <th>Quarterly Renewal Date</th>
                                <th>Yearly Renewal Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {api4Data.map(customer => {
                                console.log("Customer: ", customer); // Log individual customer objects
                                return (
                                    <tr key={customer.id}>
                                        <td>{customer.provider_name}</td>
                                        <td>{customer.product_name}</td>
                                        <td>{customer.premium}</td>
                                        <td>{customer.quarterly_renewal_dates}</td>
                                        <td>{customer.yearly_renewal_date}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    );
}

export default App;
