import React, { useState } from 'react';
import axios from 'axios';
import './AddProduct.css';
import categoriesData from '../Data/categories.json';

const AddProduct = () => {
  const [productData, setProductData] = useState({
    productname: '',
    provider_id: '',
    claim_status: '',
    ppt_min: '',
    ppt_max: '',
    policy_term_min: '',
    policy_term_max: '',
    illustration_pdf_link: '',
    rider: '',
    brochure: '',
    min_age: '',
    max_age: '',
    sum_assured: '',
    product_benefits: [{ description: '', priority: '' }], // Example structure for Benefits array
    product_highlights: [{ description: '', priority: '' }], // Example structure for Highlights array
    product_desc: [{ description: '', priority: '' }], // Example structure for productdescription array
    product_faq: [{ faq_question: '', faq_answer: '', priority: '' }], // Example structure for productfaq array
    product_feature: [{ description: '', priority: '' }], // Example structure for productfeature array

  });

  const [alertMessage, setAlertMessage] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');


  const categories = categoriesData.categories;


  const handleInputChange = (e, field, index) => {
    const { value, name } = e.target;
    if (field === 'product_desc' || field === 'product_benefits' || field === 'product_highlights' || field === 'product_faq' || field === 'product_feature') {
      const updatedField = [...productData[field]];
      // Split the name to extract property name and index
      const [propertyName] = name.split('-');
      // Update the corresponding property in the array element
      updatedField[index] = {
        ...updatedField[index],
        [propertyName]: value
      };
      setProductData({
        ...productData,
        [field]: updatedField,
      });
    } else {
      setProductData({
        ...productData,
        [name]: value,
      });
    }
  };





  const handleAddParagraph = (field) => {
    setProductData({
      ...productData,
      [field]: productData[field] ? [...productData[field], ''] : [''],
    });
  };




  const handleRemoveParagraph = (field, index) => {
    const updatedField = productData[field].filter((_, i) => i !== index);
    setProductData({
      ...productData,
      [field]: updatedField,
    });
  };


  const validateForm = () => {
    if (!productData.productname.trim()) {
      setAlertMessage('Please enter a product name');
      return false;
    }

    if (!selectedCategoryId) {
      setAlertMessage('Please select a category');
      return false;
    }

    if (!productData.provider_id.trim()) {
      setAlertMessage('Please select a provider id');
      return false;
    }

    if (!productData.claim_status.trim()) {
      setAlertMessage('Please select a claim status');
      return false;
    }

    if (!productData.ppt_min.trim()) {
      setAlertMessage('Please select a ppt minimum');
      return false;
    }

    if (!productData.ppt_max.trim()) {
      setAlertMessage('Please select a ppt maximum');
      return false;
    }

    if (!productData.min_age.trim()) {
      setAlertMessage('Please select a minimum age');
      return false;
    }

    if (!productData.max_age.trim()) {
      setAlertMessage('Please select a maximum age');
      return false;
    }

    if (!productData.policy_term_min.trim()) {
      setAlertMessage('Please select a policy term minimum');
      return false;
    }

    if (!productData.policy_term_max.trim()) {
      setAlertMessage('Please select a policy term maximum');
      return false;
    }

    if (!productData.illustration_pdf_link.trim()) {
      setAlertMessage('Please select PDF File');
      return false;
    }

    if (!productData.brochure.trim()) {
      setAlertMessage('Please select brochure file');
      return false;
    }

    if (!productData.rider.trim()) {
      setAlertMessage('Please select rider');
      return false;
    }

    if (!productData.sum_assured.trim()) {
      setAlertMessage('Please select sum assured');
      return false;
    }

     // Validation for product descriptions
  for (const desc of productData.product_desc) {
    if (!desc.description.trim() || !desc.priority.trim()) {
      setAlertMessage('Please fill out all product descriptions and priorities');
      return;
    }
  }

  // Validation for product benefits
  for (const benefit of productData.product_benefits) {
    if (!benefit.description.trim() || !benefit.priority.trim()) {
      setAlertMessage('Please fill out all product benefits and priorities');
      return;
    }
  }

  

    for (const highlight of productData.product_highlights) {
      if (!highlight.description.trim() || !highlight.priority.trim()) {
        setAlertMessage('Please fill out all product highlights and priorities');
        return;
      }
    }

      // Validation for product FAQs
  for (const faq of productData.product_faq) {
    if (!faq.faq_question.trim() || !faq.faq_answer.trim() || !faq.priority.trim()) {
      setAlertMessage('Please fill out all product FAQs, answers, and priorities');
      return;
    }
  }

    // Validation for product features
    for (const feature of productData.product_feature) {
      if (!feature.description.trim() || !feature.priority.trim()) {
        setAlertMessage('Please fill out all product features and priorities');
        return;
      }
    }

    return true;
  };






  const addProductToAPI = () => {

    if (!validateForm()) {
      return;
    }


    const productDataToSend = {
      product: {
        product_name: productData.productname,
        category_id: selectedCategoryId,
        // providername: productData.provider_id,
        provider_id: parseInt(productData.provider_id),
        claim_status: productData.claim_status,
        ppt_min: parseInt(productData.ppt_min),
        ppt_max: parseInt(productData.ppt_max),
        policy_term_min: parseInt(productData.policy_term_min),
        policy_term_max: parseInt(productData.policy_term_max),
        min_age: parseInt(productData.min_age),
        max_age: parseInt(productData.max_age),
        illustration_pdf_link: productData.illustration_pdf_link,
        rider: productData.rider,
        brochure: productData.brochure,
        sum_assured: parseInt(productData.sum_assured)
      },
      product_highlights: productData.product_highlights.map(highlight => ({
        description: highlight.description,
        priority: highlight.priority
      })),
      product_benefits: productData.product_benefits.map(benefit => ({
        description: benefit.description,
        priority: benefit.priority
      })),
      product_feature: productData.product_feature.map(feature => ({
        description: feature.description,
        priority: feature.priority
      })),
      product_faq: productData.product_faq ? productData.product_faq.map(faq => ({
        faq_question: faq.faq_question,
        faq_answer: faq.faq_answer,
        priority: faq.priority
      })) : [],
      product_desc: productData.product_desc.map(desc => ({
        description: desc.description,
        priority: desc.priority,
      }))
    };

    // Now send the productDataToSend object to the backend using Axios POST request
    axios.post(`https://api.indiainsurancedeals.com/v1/common/productInsertAll.php`, productDataToSend, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        // Handle successful response
        console.log('Product added:', response);
        setAlertMessage('Product added successfully.');

        // Reset productData here if needed
      })
      .catch((error) => {
        // Handle error
        console.error('Error adding product:', error);
        // Set appropriate error message or handle error state
      });

    console.log('Product data sent to backend:', productDataToSend);
    console.log('Checking for product_description:', productData.product_desc);
    console.log('Highlights: ', productData.product_highlights);
    console.log('Benefits :', productData.product_benefits)

  };




  const additionalFieldsVisible = productData.productname.trim() !== '';

  return (
    <>
      <div className='container_product'>
        <div className='innerBox'>
          <div className='innerdiv'>
            <label>Product name</label>
            <input
              type='text'
              name='productname'
              placeholder='Enter product Name'
              value={productData.productname}
              onChange={(e) => handleInputChange(e)}
            />

            {/* Additional input fields */}
            {additionalFieldsVisible && (
              <div className='additionalFields'>
                <div>
                  <label>Product Description</label>
                  {productData.product_desc.map((desc, index) => (
                    <div key={index}>
                      <textarea
                        placeholder='Enter Product Description'
                        value={desc.description}
                        onChange={(e) => handleInputChange(e, 'product_desc', index)}
                        name={`description-${index}`} // Include both property name and index separated by '-'
                      ></textarea>
                      <input
                        type="text"
                        placeholder="Priority"
                        value={desc.priority}
                        onChange={(e) => handleInputChange(e, 'product_desc', index)}
                        name={`priority-${index}`} // Include both property name and index separated by '-'
                      />

                      {index > 0 && (
                        <button onClick={() => handleRemoveParagraph('product_desc', index)}>
                          -
                        </button>
                      )}
                      <button onClick={() => handleAddParagraph('product_desc')}>+</button>
                    </div>
                  ))}
                </div>


                <div>
                  <label>Benefits</label>
                  {productData.product_benefits.map((benefit, index) => (
                    <div key={index}>
                      <textarea
                        placeholder='Enter Product Benefit'
                        value={benefit.description}
                        onChange={(e) => handleInputChange(e, 'product_benefits', index)}
                        name="description" // Add name attribute for description
                      ></textarea>
                      <input
                        type="text"
                        placeholder="Priority"
                        value={benefit.priority}
                        onChange={(e) => handleInputChange(e, 'product_benefits', index)}
                        name="priority"
                      />
                      {index > 0 && (
                        <button onClick={() => handleRemoveParagraph('product_benefits', index)}>-</button>
                      )}
                      <button onClick={() => handleAddParagraph('product_benefits')}>+</button>
                    </div>
                  ))}
                </div>

                <div>
                  <label>Highlights</label>
                  {productData.product_highlights.map((highlight, index) => (
                    <div key={index}>
                      <textarea
                        placeholder='Enter Product Highlight'
                        value={highlight.description}
                        onChange={(e) => handleInputChange(e, 'product_highlights', index)}
                        name="description" // Add name attribute for description
                      ></textarea>
                      <input
                        type="text"
                        placeholder="Priority"
                        value={highlight.priority}
                        onChange={(e) => handleInputChange(e, 'product_highlights', index)}
                        name="priority" // Add name attribute for priority
                      />
                      {index > 0 && (
                        <button onClick={() => handleRemoveParagraph('product_highlights', index)}>-</button>
                      )}
                      <button onClick={() => handleAddParagraph('product_highlights')}>+</button>
                    </div>
                  ))}
                </div>

                <div>
                  <label>Product FAQ's</label>
                  {productData.product_faq.map((product_faq, index) => (
                    <div key={index}>
                      <textarea
                        placeholder='Enter Product FAQ'
                        value={product_faq.faq_question}
                        onChange={(e) => handleInputChange(e, 'product_faq', index)}
                        name="faq_question" // Add name attribute for question
                      ></textarea>
                      <input type="text"
                        placeholder='FAQ Answers'
                        value={product_faq.faq_answer}
                        onChange={(e) => handleInputChange(e, 'product_faq', index)}
                        name='faq_answer' />
                      <input
                        type="text"
                        placeholder="Priority"
                        value={product_faq.priority}
                        onChange={(e) => handleInputChange(e, 'product_faq', index)}
                        name="priority" // Add name attribute for priority
                      />
                      {index > 0 && (
                        <button onClick={() => handleRemoveParagraph('product_faq', index)}>-</button>
                      )}
                      <button onClick={() => handleAddParagraph('product_faq')}>+</button>
                    </div>
                  ))}
                </div>

                <div>
                  <label>Product Feature</label>
                  {productData.product_feature.map((product_feature, index) => (
                    <div key={index}>
                      <textarea
                        placeholder='Enter Product Feature'
                        value={product_feature.description}
                        onChange={(e) => handleInputChange(e, 'product_feature', index)}
                        name="description" // Add name attribute for description
                      ></textarea>
                      <input
                        type="text"
                        placeholder="Priority"
                        value={product_feature.priority}
                        onChange={(e) => handleInputChange(e, 'product_feature', index)}
                        name="priority" // Add name attribute for priority
                      />
                      {index > 0 && (
                        <button onClick={() => handleRemoveParagraph('product_feature', index)}>-</button>
                      )}
                      <button onClick={() => handleAddParagraph('product_feature')}>+</button>
                    </div>
                  ))}
                </div>

                <div className="subcontainer">
                  <div>
                    <label>Min Age - Max Age</label>
                    <input
                      type='text'
                      name='min_age'
                      placeholder='Enter Min Age'
                      value={productData.min_age}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      type='text'
                      name='max_age'
                      placeholder='Enter Max Age'
                      value={productData.max_age}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div>
                    <label>Policy Term</label>
                    <input
                      type='text'
                      name='policyTerm'
                      placeholder='Enter Policy Term'
                      value={productData.policyTerm}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div>
                    <label>Provider ID</label>
                    <input type="text"
                      name='provider_id'
                      placeholder='Enter Provider ID'
                      value={productData.provider_id}
                      onChange={(e) => handleInputChange(e)} />
                  </div>
                  <div className="policy-terms">
                    <div>
                      <label>Policy Term Minimum</label>
                      <input
                        type='text'
                        name='policy_term_min'
                        placeholder='Enter PT Minimum'
                        value={productData.policy_term_min}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                    <div>
                      <label>Policy Term Maximum</label>
                      <input
                        type='text'
                        name='policy_term_max'
                        placeholder='Enter PT Maximum'
                        value={productData.policy_term_max}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className='select-container'>
                    <label>Select Category</label>
                    <select
                      value={selectedCategoryId} // Use selectedCategoryId state
                      onChange={(e) => setSelectedCategoryId(e.target.value)}
                    >
                      <option value="">Select a category</option>
                      {categories.map(category => (
                        <option key={category.id} value={category.id}>
                          {category.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Sum Assured</label>
                    <input
                      type='text'
                      name='sum_assured'
                      placeholder='Enter Sum Assured'
                      value={productData.sum_assured}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div>
                    <label>Claim Status</label>
                    <input
                      type='text'
                      name='claim_status'
                      placeholder='Enter Claim Status'
                      value={productData.claim_status}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div>
                    <label>Riders</label>
                    <input
                      type='text'
                      name='rider'
                      placeholder='Enter Riders'
                      value={productData.rider}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div>
                    <label>Premium Paying Term Minimum</label>
                    <input
                      type='text'
                      name='ppt_min'
                      placeholder='Enter PPT Minimum'
                      value={productData.ppt_min}
                      onChange={(e) => handleInputChange(e)}
                    />

                    <label>Premium Paying Term Maximum</label>
                    <input
                      type='text'
                      name='ppt_max'
                      placeholder='Enter PPT Maximum'
                      value={productData.ppt_max}
                      onChange={(e) => handleInputChange(e)}
                    />

                  </div>
                  <div>
                    <label>Brochure</label>
                    <input
                      type='file'
                      name='brochure'
                      placeholder='Add Brochure'
                      value={productData.brochure}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div>
                    <label>PDF Link</label>
                    <input
                      type='file'
                      name='illustration_pdf_link'
                      placeholder='Add PDF'
                      value={productData.illustration_pdf_link}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* End of additional input fields */}
            <button onClick={addProductToAPI}>Add Product</button>
          </div>
        </div>
      </div>
      {alertMessage && <div className='alert'>{alertMessage}</div>}
    </>
  );
};

export default AddProduct;






