import React from 'react'
import { useState } from 'react';
// import usersData from './users.json';
// import Adminhome from '../AdminHome/Adminhome';
// import Products from '../Products/Products';
import Navbar from '../../Components/Navbar';
// import ReportsCard from '../../Components/ReportsCard';
import "./style.css";
import usersData from '../Data/users.json'
import ReportsHome from '../Home';
import { useNavigate } from 'react-router-dom';




const Loginpage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [sucessfull,setsucessfull]=useState(true)
    const handleUsernameChange = (event) => {
      setUsername(event.target.value);
    };
  // const navigation = useNavigate();
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();

      
  if (username.trim() === '' || password.trim() === '') {
    setErrorMessage('Please enter both username and password.');
    return; // Prevent further execution
  }
      const user = usersData.users.find(
        (user) => user.username === username && user.password === password
      );
  
      if (user) {
        alert('Login successful');
        setsucessfull(false)
        // navigation("/ReportsHome")

      } else {
        setErrorMessage('Username or password is incorrect');
        alert('Username or password is incorrect');
      }
    };
    
      return (
      <>
      {sucessfull?
      <form className='box' onSubmit={handleSubmit}>
        <h1>Admin Login</h1>
     
        <input type="text" placeholder='Enter username' value={username} onChange={handleUsernameChange} />
  
     
     
        <input type="password" placeholder='Enter password' value={password} onChange={handlePasswordChange} />

   
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <button type="submit">Login</button>
    </form>
    :""}
    {sucessfull?"":<ReportsHome/>}
       </>
      );
}

export default Loginpage