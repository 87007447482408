import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./DeleteProvider.css"

const Adminpage = () => {
  const [productData, setProductData] = useState({
    id: '',
  });
  const [productDataArray, setProductDataArray] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState([]); // State to store selected provider details
  const [selectedProviderId, setSelectedProviderId] = useState('');

  const handleProviderSelect = (e) => {
    const selectedId = e.target.value;
    setSelectedProviderId(selectedId);
    console.log('Selected provider ID:', selectedId);
  };

  const handleDeleteProvider = () => {
    if (!selectedProviderId) {
      alert('Please select a provider.');
      return;
    }
    const isConfirmed = window.confirm(`Are you sure you want to delete provider with ID: ${productData.id}? It cannot be undone once deleted`);
    if (!isConfirmed) {
      return;
    }

    axios.post('https://api.indiainsurancedeals.com/v1/common/commonDelete.php', {
      tbname: 'provider',
      id: selectedProviderId
    })
      .then((response) => {
        alert(`Provider with ID: ${productData.id} has been deleted sucessfully.`);
        console.log('Deleted provider with ID:', selectedProviderId);
        // Optionally, you can update the UI or perform other actions after successful deletion
      })
      .catch((error) => {
        console.error('Error deleting provider:', error);
      });
  };

  useEffect(() => {
    // Load the initial JSON data from the API
    axios.get('https://api.indiainsurancedeals.com/v1/common/commonDelete.php')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setProductDataArray(response.data);
          console.log(response.data)
        } else {
          console.error('Invalid product data format:', response.data);
        }
      })
      .catch((error) => {
        console.error('Error loading product data:', error);
      });
    fetchProviderDetails(1);
  }, []);


  const fetchProviderDetails = (providerId) => {
    axios.post('https://api.indiainsurancedeals.com/v1/common/readSelected.php', {
      tbname: 'provider',
      propname: 'provider',
      cols: 'provider_name,id',
      id: providerId
    })
      .then((response) => {
        console.log('Fetched provider details:', response.data);
        setSelectedProvider([response.data]); // Set selected provider details
      })
      .catch((error) => {
        console.error('Error fetching provider details:', error);
      });
  };
  useEffect((providerId) => {
    // Call fetchProviderDetails when component mounts
    fetchProviderDetails(providerId);
  }, []);

  return (
    <div className="delete_box">
      <div className="inner_dl_box">
        {/* {console.log(selectedProvider)} */}
        <select className="provider-select" onChange={handleProviderSelect} value={selectedProviderId}>
          <option value="">Select Provider</option>
          {selectedProvider.length > 0 && selectedProvider[0].data.map((provider, index) => (

            <option key={index} value={provider.id}>
              {provider.provider_name}
            </option>
          ))}
        </select>
        <button className="deletebtn" onClick={handleDeleteProvider}>
          Delete Provider
        </button>
      </div>
    </div>
  );
};

export default Adminpage;