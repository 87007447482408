import React, { useState } from 'react';
import axios from 'axios';
import './AddProvider.css';
// import categoriesData from '../Data/categories.json';

const AddProvider = () => {
  const [productData, setProductData] = useState({
    provider_name: '',
    Description: '',
    insurance_type: '',
    claim_status: '',
    website: '',
    Address_line_1: '',
    Address_line_2: '',
    city: '',
    district: '',
    state: '',
    country: '',
    pincode: '',
    contact: '',
  });
  const [alertMessage, setAlertMessage] = useState('');
  // const categories = categoriesData.categories;

  const handleInputChange = (e, field, index) => {
    let inputValue = e.target.value;

    if (field === 'pincode' || field === 'contact') {
      inputValue = inputValue.replace(/\D/g, '');
      if (field === 'contact') {
        inputValue = inputValue.slice(0, 10); // Limit to 10 characters
      }
    }

    if (field === 'Benefits' || field === 'Highlights' || field === 'productdescription') {
      const updatedField = [...productData[field]];
      updatedField[index] = inputValue;
      setProductData({
        ...productData,
        [field]: updatedField,
      });
    } else {
      setProductData({
        ...productData,
        [e.target.name]: inputValue,
      });
    }
  };

  const addProductToAPI = () => {
    // Check if any field is empty
    for (const key in productData) {
      if (productData[key] === '') {
        alert('Please fill in all fields.');
        
        return; // Exit function if any field is empty
      }
    }

    // Add tbname and propname to the product data
    const dataWithParams = {
      ...productData,
      tbname: 'provider',
      propname: 'provider',
    };

    console.log('Data to be sent:', dataWithParams);

    axios
      .post('https://api.indiainsurancedeals.com/v1/common/create.php', dataWithParams)
      .then((response) => {
        console.log('Product added:', response);
        setProductData({
          provider_name: '',
          Description: '',
          insurance_type: '',
          claim_status: '',
          website: '',
          Address_line_1: '',
          Address_line_2: '',
          city: '',
          district: '',
          state: '',
          country: '',
          pincode: '',
          contact: '',
        });
        alert('Provider added successfully.');
      })
      .catch((error) => {
        if (error.response) {
          console.error('Server error:', error.response.data);
        } else if (error.request) {
          console.error('No response from server');
        } else {
          console.error('Error setting up the request:', error.message);
        }
        setAlertMessage('Error adding product. Please check the data and try again.');
      });
  };

  const handleReset = () => {
    setProductData({
      provider_name: '',
      Description: '',
      insurance_type: '',
      claim_status: '',
      website: '',
      Address_line_1: '',
      Address_line_2: '',
      city: '',
      district: '',
      state: '',
      country: '',
      pincode: '',
      contact: '',
    });
    setAlertMessage('');
  };

  return (
    <>
      <div className='container_provider'>
        <div className='innerBox'>
          <div className='innerdiv'>
            <label>Provider Name</label>
            <input
              type='text'
              name='provider_name'
              placeholder='Enter Provider Name'
              value={productData.provider_name}
              onChange={(e) => handleInputChange(e, 'provider_name')}
            />
            <label>Description</label>
            <input
              type='text'
              name='Description'
              placeholder='Enter Description'
              value={productData.Description}
              onChange={(e) => handleInputChange(e, 'Description')}
            />
            <div className='select-container'>
              <label>Insurance Type</label>
              <select
                value={productData.insurance_type}
                name='insurance_type'
                onChange={(e) => handleInputChange(e, 'insurance_type')}
              >
                <option value=''>Select Insurance Type</option>
                <option value='Health Insurance'>Health Insurance</option>
                <option value='Home Insurance'>Home Insurance</option>
                <option value='Auto Insurance'>Auto Insurance</option>
              </select>
            </div>
            <label>Claim Status</label>
            <input
              type='number'
              name='claim_status'
              placeholder='Enter Claim Status'
              value={productData.claim_status}
              onChange={(e) => handleInputChange(e, 'claim_status')}
            />
            <label>Website</label>
            <input
              type='url'
              name='website'
              placeholder='Enter Website'
              value={productData.website}
              onChange={(e) => handleInputChange(e, 'website')}
            />
            <label>Address Line 1</label>
            <input
              type='text'
              name='Address_line_1'
              placeholder='Enter Address line1'
              value={productData.Address_line_1}
              onChange={(e) => handleInputChange(e, 'Address_line_1')}
            />
            <label>Address Line 2</label>
            <input
              type='text'
              name='Address_line_2'
              placeholder='Enter Address line2'
              value={productData.Address_line_2}
              onChange={(e) => handleInputChange(e, 'Address_line_2')}
            />
            <label>City</label>
            <input
              type='text'
              name='city'
              placeholder='Enter City'
              value={productData.city}
              onChange={(e) => handleInputChange(e, 'city')}
            />
            <label>District</label>
            <input
              type='text'
              name='district'
              placeholder='Enter District'
              value={productData.district}
              onChange={(e) => handleInputChange(e, 'district')}
            />
            <label>State</label>
            <input
              type='text'
              name='state'
              placeholder='Enter state'
              value={productData.state}
              onChange={(e) => handleInputChange(e, 'state')}
            />
            <label>Country</label>
            <input
              type='text'
              name='country'
              placeholder='Enter Country'
              value={productData.country}
              onChange={(e) => handleInputChange(e, 'country')}
            />
            <label>Pincode</label>
            <input
              type='text'
              name='pincode'
              placeholder='Enter pincode'
              value={productData.pincode}
              onChange={(e) => handleInputChange(e, 'pincode')}
            />
            <label>Contact</label>
            <input
              type='text'
              name='contact'
              placeholder='Enter Contact'
              value={productData.contact}
              onChange={(e) => handleInputChange(e, 'contact')}
            />
            <button onClick={handleReset}>Reset</button>
            <button onClick={addProductToAPI}>Add Product</button>
          </div>
        </div>
      </div>
      {alertMessage && <div className='alert'>{alertMessage}</div>}
    </>
  );
};

export default AddProvider;
