import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './style.css';

const UploadImage = () => {
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState('');
  const [imageDescription, setImageDescription] = useState('');
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageData, setSelectedImageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchImages = async () => {
    try {
      const formData = new FormData();
      formData.append('table_name', 'news');

      const response = await axios.post(
        'https://api.indiainsurancedeals.com/v1/common/newsImageRead.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      setImages(response.data.data);
      setLoading(false);
      // console.log("Fetched images:", response.data.data); Log fetched images
    } catch (error) {
      console.error('Error fetching images:', error);
      setError('Error fetching images: ' + error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!image) {
      alert('Please select an image');
      return;
    }

    const formData = new FormData();
    formData.append('image', image);
    formData.append('news_head', imageName);
    formData.append('news_desc', imageDescription);

    try {
      const response = await axios.post(`https://api.indiainsurancedeals.com/v1/common/newsImageUpload.php?table_name=news`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(response.data);
      fetchImages();
    } catch (error) {
      console.error('Error uploading image:', error);
    }

    alert("Image Uploaded Successfully");
  };

  const handleImageSelect = async (e) => {
    const selectedId = e.target.value;
    const selected = images.find(image => image.id === selectedId);
    setSelectedImage(selected);

    try {
      const response = await axios.get(`https://api.indiainsurancedeals.com/v1/common/imageDetail.php?id=${selectedId}`);
      setSelectedImageData(response.data);
    } catch (error) {
      console.error('Error fetching selected image details:', error);
    }
  };

  const handleUpdateImage = async () => {
    if (!selectedImage) {
      alert('Please select an image to update');
      return;
    }

    
  // Check if selectedImageData is not null before accessing its properties
  if (!selectedImageData) {
    alert('Please select an image with data to update');
    return;
  }


    console.log("Selected Image:", image);
    // Create FormData object
    const formData = new FormData();
    formData.append('id', selectedImage.id);
    formData.append('table_name', 'news'); // Ensure table_name is included
    formData.append('image', image);

    // Include text data if available
  if (selectedImageData.news_head) {
    formData.append('news_head', selectedImageData.news_head);
  }
  if (selectedImageData.news_desc) {
    formData.append('news_desc', selectedImageData.news_desc);
  }

// console.log("Selected Image Data:", selectedImageData);
// console.log("Selected Image Head:", selectedImageData.news_head);
// console.log("Selected Image Description:", selectedImageData.news_desc);


   
  
    try {
      const response = await axios.post(`https://api.indiainsurancedeals.com/v1/common/newsImageUpdate.php?table_name=news&record_id=${selectedImage.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      console.log(response.data);
      fetchImages(); // Fetch images after update
      alert("Image Updated Successfully");
    } catch (error) {
      console.error('Error updating image:', error);
    }

    
  };

  // Update head value
  const handleHeadChange = (e) => {
    const value = e.target.value;
    setSelectedImageData(prevState => ({
      ...prevState,
      news_head: value
    }));
  };

// Update description value
const handleDescriptionChange = (e) => {
  const value = e.target.value;
  setSelectedImageData(prevState => ({
    ...prevState,
    news_desc: value
  }));
};
  

  return (
    <div className="UploadImage card">
      <h4>News</h4>
      <hr />
      <div className="card AddImage fadeIn">
        <input
          type="file"
          className="w-50 mx-auto mt-2"
          onChange={handleImageChange}
        />
        <input
          type="text"
          className="w-50 mx-auto mt-2"
          placeholder="Image Head"
          value={imageName}
          onChange={(e) => setImageName(e.target.value)}
        />
        <input
          type="text"
          className="w-50 mx-auto mt-2"
          placeholder="Image Description"
          value={imageDescription}
          onChange={(e) => setImageDescription(e.target.value)}
        />

        <button className="btn-add" onClick={handleUpload}>Add Image</button>
      </div>
      
      <div className="card UpdateImage fadeIn">
        <select className="w-50 mx-auto mt-2" onChange={handleImageSelect}>
          <option value="">Select an image to update</option>
          {images && images.length > 0 && images.map(image => (
            <option key={image.id} value={image.id}>{image.news_head}</option>
          ))}
        </select>
        {selectedImageData && (
          <div className="update-form">
          <input
            type="text"
            className="w-50 mx-auto mt-2"
            placeholder="Image Head"
            value={selectedImageData.news_head}
            onChange={handleHeadChange}
          />
          <input
            type="text"
            className="w-50 mx-auto mt-2"
            placeholder="Image Description"
            value={selectedImageData.news_desc}
            onChange={handleDescriptionChange}
          />
          <input
            type="file"
            className="w-50 mx-auto mt-2"
            onChange={handleImageChange}
          />
          <button className="btn-update" onClick={handleUpdateImage}>Update Image</button>
        </div>
        )}
      </div>

      {/* Display uploaded images in carousel */}
      <div className="image-carousel">
        <h1>Image Carousel</h1>
        <Carousel>
          {images.map((image) => (
            <div key={image.id}>
              <img src={`https://api.indiainsurancedeals.com/v1/common/${image.url_link}`} alt={image.news_desc} />
              <p className="legend">{image.news_desc}</p>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default UploadImage;






