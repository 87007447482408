import React, { useState } from 'react';
import './AddEmployee.css';

const AddEmployee = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        department: '',
        email_id: '',
        password: '',
        home_number: '',
        office_number: '',
        mobile_number: '',
        address_line1: '',
        address_line2: '',
        state: '',
        city: '',
        country: '',
        pincode: '',
        is_email_verified: true,
        is_mobile_verified: true
    });
    const [error, setError] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const clearFormData = () => {
        setFormData({
            first_name: '',
            last_name: '',
            department: '',
            email_id: '',
            password: '',
            home_number: '',
            office_number: '',
            mobile_number: '',
            address_line1: '',
            address_line2: '',
            state: '',
            city: '',
            country: '',
            pincode: '',
            is_email_verified: true,
            is_mobile_verified: true
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        
        // Validation
        if (!formData.first_name || !formData.last_name || !formData.email_id || !formData.department) {
            setError('All fields are required');
            return;
        }
        // Reset error
        setError('');

        try {
            // Add API call to add employee using formData
            const response = await fetch(`https://api.indiainsurancedeals.com/v1/common/create.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    tbname: 'employee',
                    propname: 'employee',
                    ...formData
                })
            });

            if (response.ok) {
                clearFormData();
                setShowAlert(true);
                console.log('Employee added successfully');
            } else {
                const errorData = await response.json();
                setError('Failed to add employee: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error adding employee:', error.message);
        }
    };

    return ( 
        <div className="add-employee-form">
            <h2>Add Employee</h2>
            {error && <p className="error">{error}</p>}
            {showAlert && (
                <div className="success-alert">
                    Employee added successfully!
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <input type="text" name="first_name" placeholder="First Name" value={formData.first_name} onChange={handleChange} />
                <input type="text" name="last_name" placeholder="Last Name" value={formData.last_name} onChange={handleChange} />
                <input type="tel" name="mobile_number" placeholder="Mobile Number" value={formData.mobile_number} onChange={handleChange} />
                <input type="text" name="department" placeholder="Department" value={formData.department} onChange={handleChange} />
                <input type="email" name="email_id" placeholder="Email" value={formData.email_id} onChange={handleChange} />
                <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} />
                {/* Include other fields similarly */}
                <button type="submit">Submit</button>
            </form>
        </div>
     );
}
 
export default AddEmployee;

